import { Component } from 'react';

class App extends Component {

  render() {
    return 'Hello!';
  }
}


export default App;
